/* eslint-disable function-paren-newline */
/* eslint-disable import/no-unresolved */
import { has, reject } from 'underscore';

(function () {
  angular.module('7Shop.Bootstrap')
    .service('ShopBootstrap', ShopBootstrap);

  function ShopBootstrap(
    $q,
    $window,
    $http,
    $log,
    $state,
    $translate,
    $injector,
    Modules,
    userService,
    companyService,
    ConfigService,
    currency,
    LoginData,
    LocalData,
    NabENV,
    SevenClientCoreSvc,
    GameService,
    ShopEnvironment,
    GravitySettings,
    Touch,
    IframePubSub,
    SevenRoutes
  ) {
    var LOAD_DATA = {};
    const LOADED_PRODUCTS = [];
    const MODULES_DEFINITION = {
      LuckyX: {
        modules: ['shopApp.nabLuckyXDisplay'],
        hideBetslip: false
      },
      GreyhoundRaces: {
        modules: ['shopApp.nabGreyhoundRaces'],
        hideBetslip: true
      },
      VirtualGreyhoundRaces: {
        modules: ['shopApp.nabGreyhoundRaces'],
        hideBetslip: true
      },
      VirtualHorseRaces: {
        modules: ['shopApp.nabHorseRaces'],
        hideBetslip: false
      },
      LuckySix: {
        modules: ['shopApp.nabLuckySixDisplay'],
        hideBetslip: true
      },
      SlotCarRaces: {
        modules: ['shopApp.SevenSCR'],
        hideBetslip: false
      },
      VirtualMotorcycleSpeedway: {
        modules: ['shopApp.SevenVMS'],
        hideBetslip: false
      }
    };
    var self = this;
    let mainAppLoaded = false;
    // let baseUrl = NabENV.api.gravity.shop.replace('7platform.com', '7platform.info');

    function setLanguageProvider(lang) {
      ShopEnvironment.setLanguage(lang);
      angular.module('7Shop.Core').provider('lang', function Lang() {
        this.lang = lang;
        this.$get = function () {
          return lang;
        };
      });
    }

    function injectAngularLocale(lang) {
      var loader = Modules.getLoader();
      var mapper = NabENV.angular.i18nMapper;
      lang = has(mapper, lang) ? mapper[lang] : lang;
      return loader.load('node_modules/angular-i18n/angular-locale_' + lang + '.js');
    }

    function setNumberDelimiter(numberDelimiter) {
      var $locale = $injector.get('$locale');
      var groupSeparator = numberDelimiter[1];
      var decimalSeparator = numberDelimiter[numberDelimiter.length - 3];
      if (groupSeparator === '#') { groupSeparator = ''; }
      $locale.NUMBER_FORMATS.GROUP_SEP = groupSeparator;
      $locale.NUMBER_FORMATS.DECIMAL_SEP = decimalSeparator;
    }

    function getGames() {
      var games = [];
      LOAD_DATA.deprecated.clientAppModules.forEach((module) => {
        if (MODULES_DEFINITION[module.id]) {
          const moduleDefinition = MODULES_DEFINITION[module.id];
          games.push({
            directConfigRoute: null,
            id: module.id,
            mode: null,
            priority: 30,
            submodules: [],
            type: 'product',
            cpvUuid: module.cpvUuid,
            local: {
              modules: moduleDefinition.modules,
              connectToScm: false,
              hideBetslip: moduleDefinition.hideBetslip,
              stakePerBet: GravitySettings.getModuleDataKeyValue(`module.${module.id}`, 'stakePerBet'),
              settings: GravitySettings.getModuleDataKeyValue(`module.${module.id}`, 'settings')
            }
          });
        }
      });
      return games;
    }

    /**
     *
     * @param {Array} loaded - List of loaded products
     */
    this.loaded = (loaded) => {
      const allActiveProducts = getGames();
      const gravityData = GravitySettings.getData();

      const productsToLoad = allActiveProducts.filter((product) => {
        const widgetData = `widget.${product.id}`;
        const gameWidget = gravityData[widgetData] || {};
        return gameWidget.source[0].config.frameId === 'NGS';
      });

      const driver = IframePubSub.getDriver();
      let notLoaded = [];
      LOADED_PRODUCTS.push(...loaded);
      notLoaded = reject(productsToLoad, (product) => LOADED_PRODUCTS.indexOf(product.id) > -1);

      if (notLoaded.length === 0) {
        // all products are loaded, singal Master that we are ready
        driver.emit({
          action: 'Slave.Loaded',
          data: {}
        });
      }
    };

    this.boot = function (gameId) {
      return $q((resolve) => {
        IframePubSub.addFrame({
          debug: true,
          slaveId: gameId, // this should be dynamic
          allowedOrigins: '*',
          eventPropagation: {
            click: true,
            keydown: '*'
          },
          eventListeners: {
            keydown: '*'
          },
          load: (loadEvent) => {
            let touchModulePromise = null;
            let numDelimiter = null;
            const locale = loadEvent.data.locale.iso1;
            LOAD_DATA = loadEvent.data;
            GravitySettings.setData(LOAD_DATA.deprecated.cms.appSettings);
            sessionStorage.shopToken = LOAD_DATA.tokens.accessToken;
            setLanguageProvider(locale);
            angular.extend(SevenRoutes, {
              mainUrl: LOAD_DATA.deprecated.LocalAppConfig.sevenUrl,
              ngs: NabENV.api.ngs,
              localAdminConfig: NabENV.api.localAdminConfig,
              localAdminLogin: NabENV.api.localAdminLogin,
              NARLoginRoute: NabENV.NARLoginRoute ? NabENV.NARLoginRoute.url : '',
              bonusBaseUrl: NabENV.bonus.baseUrl || '',
              assetsUrl: NabENV.assetsServer ? NabENV.assetsServer.url : '',
              fiscalServiceUrl: NabENV.fiscalService ? NabENV.fiscalService.url : '',
              taxAuth: NabENV.taxAuth ? NabENV.taxAuth : ''
            });
            angular.extend(LoginData, {
              betshop: {
                uuid: LOAD_DATA.betshop.uuid
              },
              company: {
                ...LOAD_DATA.company,
                currency: LOAD_DATA.money.currencyDisplayValue
              },
              user: {
                uuid: LOAD_DATA.user.uuid,
                language: locale
              },
              appConfig: {},
              cloudMessaging: LOAD_DATA.deprecated.cloudMessaging
            });
            angular.extend(LocalData, {
              company: LOAD_DATA.company,
              betshop: {
                companyId: LOAD_DATA.company.id,
                betshopId: LOAD_DATA.betshop.id
              },
              device: {
                uuid: LOAD_DATA.device.uuid,
                token: LOAD_DATA.deprecated.device.token
              }
            });
            companyService.setData(LOAD_DATA.company);
            ConfigService.setData();
            companyService.setSettings(LOAD_DATA.deprecated.cms.appSettings.config || {});
            ShopEnvironment.setDc(LOAD_DATA.company.datacenter.split('_')[1]);
            $translate.refresh();
            // eslint-disable-next-line prefer-const
            numDelimiter = GravitySettings.getModuleDataKeyValue('config', 'numberDelimiter');
            if (numDelimiter) { setNumberDelimiter(numDelimiter); }

            // if we are in touch mode load dedicated touch module
            // and set envirnoment state touch as true
            if (ShopEnvironment.isInTouchMode()) {
              Touch.setInTouch();
              touchModulePromise = Modules.loadModule('7Shop.Touch');
            }
            $q.all([
              touchModulePromise,
              injectAngularLocale(locale),
              self.loadTheme(
                ShopEnvironment.getUrlParameter('theme'),
                GravitySettings.getModuleDataKeyValue('config', 'theme')
              )
            ]).catch((err) => {
              $log.warn(' [NGS.Bootstrap] Failed getting resources on boot', {
                locale: locale,
                upstream_code: err.data?.httpCode || err.data?.code || err.response?.status || err.status,
                upstream_message: err.data?.message || err.response?.message || err.message || err.statusText
              });
            }).finally(() => {
              resolve();
            });
          }
        });
      });
    };

    this.loadMainApp = function () {
      var currencySetting;
      var currencyDisplayValue;
      var games = getGames();

      $window.SHOP_APP_ACTIVE_MODULES = GameService.getActiveGamesModules(
        games,
        NabENV
      );

      userService.setData(LOAD_DATA);

      companyService.setData(LOAD_DATA.company);
      ConfigService.setData();

      currencySetting = companyService.getSetting('currency');
      currencyDisplayValue = GravitySettings.getModuleDataKeyValue('currency', 'displayValue');
      currency.value = currencyDisplayValue !== undefined ? currencyDisplayValue
        : companyService.data && companyService.data.currency;
      if (currencySetting) {
        currency.position = currencySetting.position;
      }

      // load login
      return Modules.loadModule('home').then(function () {
        if (mainAppLoaded) {
          // prevent running SevenClientCoreSvc.run two times
          return true;
        }

        mainAppLoaded = true;
        $log.info(' [NGS.Login] Loaded home module on login');
        return SevenClientCoreSvc.run({
          clientAppModules: games,
          configRoutes: LOAD_DATA.deprecated.configRoutes
        });
      }).then(function () {
        $log.info(' [NGS.Login] Client core init done.');
        return $state.go('app.home');
      });
    };

    this.getLoadData = function () {
      return LOAD_DATA;
    };

    this.fetchTranslations = function () {
      var browserLang = $translate.resolveClientLocale().substring(0, 2).toLowerCase();
      return $http.get(NabENV.api.gravity.shop + '/translations', {
        headers: {
          'X-Nsft-SCD-Locale': browserLang || 'en'
        }
      });
    };

    /**
     * @param {String} themeName
     * @param {String} companyThemeName
     */
    this.loadTheme = function (themeName, companyThemeName) {
      // we need this in order for cache bust to find files for rewrite
      var THEMES = {
        touch_default: () => import(
          /* webpackChunkName: "theme_touch_default" */
          'theme_touch_default'),
        desktop_default: () => import(/* webpackChunkName: "theme_desktop_default" */
          'theme_desktop_default'),
        'desktop_black-yellow': () => import(
          /* webpackChunkName: "desktop_black-yellow" */
          'theme_desktop_black-yellow'),
        'desktop_dark-green': () => import(
          /* webpackChunkName: "desktop_dark-green" */
          'theme_desktop_dark-green'),
        desktop_magenta: () => import(
          /* webpackChunkName: "theme_desktop_magenta" */
          'theme_desktop_magenta')
      };
      var loadTheme = themeName;
      var deviceType = Touch.isTouch() ? 'touch' : 'desktop';

      if (!themeName && companyThemeName) {
        loadTheme = companyThemeName;
      } else if (!themeName || !has(THEMES, deviceType + '_' + loadTheme)) {
        loadTheme = 'default';
      }

      return THEMES[deviceType + '_' + loadTheme]();
    };
  }
})();
